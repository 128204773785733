import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {
  DashboardOutlined,
  FundOutlined,
  UploadOutlined,
  DollarOutlined,
  QuestionOutlined,
  UserSwitchOutlined,
  DatabaseOutlined,
  AreaChartOutlined
} from '@ant-design/icons';

const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'dashboards-home',
      path: `${APP_PREFIX_PATH}/dashboards/dashoardHome`,
      title: 'sidenav.dashboard.home',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin"],
    },
    {
      key: 'dashboards-home-user',
      path: `${APP_PREFIX_PATH}/dashboards/dashoard-User`,
      title: 'sidenav.dashboard.user',
      icon: FundOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["user"],
    }
  ]
}]

const appBoardNavTree = [{
  key: 'apps',
  path: `${APP_PREFIX_PATH}/apps`,
  title: 'sidenav.apps',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'apps-Upload-Questions',
      path: `${APP_PREFIX_PATH}/apps/Upload_Questions`,
      title: 'sidenav.dashboard.master',
      icon: UploadOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin"]
    },
    {
      key: 'apps-admin-manage_Questions',
      path: `${APP_PREFIX_PATH}/apps/manage_Questions`,
      title: 'sidenav.apps.Manage_Questions',
      icon: QuestionOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin"]
    },
    {
      key: 'apps-admin-users',
      path: `${APP_PREFIX_PATH}/apps/Users`,
      title: 'sidenav.apps.Users',
      icon: UserSwitchOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin"]
    },
    {
      key: 'apps-admin-payments',
      path: `${APP_PREFIX_PATH}/apps/Payments`,
      title: 'sidenav.apps.Payments',
      icon: DollarOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin"]
    },
    {
      key: 'apps-admin-Subject',
      path: `${APP_PREFIX_PATH}/apps/subjects`,
      title: 'sidenav.apps.subjects',
      icon: DatabaseOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin", "user"]
    },
    {
      key: 'apps-admin-chapters',
      path: `${APP_PREFIX_PATH}/apps/chapters`,
      title: 'sidenav.apps.chapters',
      icon: AreaChartOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin", "user"]
    },
    {
      key: 'apps-admin-questions',
      path: `${APP_PREFIX_PATH}/apps/questions`,
      title: 'sidenav.apps.questions',
      icon: QuestionOutlined,
      breadcrumb: false,
      submenu: [],
      role: ["admin", "user"]
    }
  ]
}]


const navigationConfig = [
  ...dashBoardNavTree,
  ...appBoardNavTree
]

export default navigationConfig;
